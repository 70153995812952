import {observable, makeObservable, makeAutoObservable, runInAction, action, computed} from 'mobx'
import React from "react";
import axios from "axios";
import {API_BASE} from "../constants/api";
import { ToastContainer, toast } from 'react-toastify';

class AppStore {


    currentUpdateItem = {
        street: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        name: '',
        email: '',
        phone: '',
        surname: '',
        iban: '',
        modelid: '',
        brandid: '',
        brand: '',
        model: '',
        storage: '',
        color: '',
        price: '',
        condition: '',
        fulladdress: '',
        captcha:false,
    }
    fulladdress = ''
    street = ''
    city = ''
    state = ''
    postcode = ''
    country = ''
    name = ''
    email = ''
    phone = ''
    surname = ''
    iban = ''

    currentItem = {}

    brands = []

    models = []

    storage = []

    colors = []

    prices = []
    brokenPrices = []

    activeLang = "en"
    loading=false
    constructor(Username) {
        makeObservable(this, {
            loading: observable,
            fulladdress: observable,
            street: observable,
            city: observable,
            state: observable,
            postcode: observable,
            country: observable,
            name: observable,
            email: observable,
            phone: observable,
            surname: observable,
            iban: observable,
            brands: observable,
            models: observable,
            storage: observable,
            colors: observable,
            prices: observable,
            brokenPrices: observable,
            currentUpdateItem: observable,
            activeLang: observable,
            currentItem: observable,
        })
    }


    post = (url, data) => {
        return axios.post(`${API_BASE}${url}`, data)
    }

    start = () => {
        this.getBrands()

    }

    isExists(key){
        if(this.currentUpdateItem[key]){
            return true
        }else{
            return false
        }
    }
    updateCurrentItem(key, value) {

        runInAction(() => {

            let newObj = this.currentUpdateItem
            this.currentUpdateItem = {}
            if (newObj[key]) {
                newObj[key] = value
                this.currentUpdateItem = newObj
            } else {
                Object.assign(newObj, {[key]: value});
                this.currentUpdateItem = newObj

            }

            // toast(JSON.stringify(this.currentUpdateItem), {
            //     position: "top-right",
            // })

        })
    }

    deleteCurrentItem(key) {
        alert(key)
        runInAction(() => {
            let newObj = this.currentUpdateItem
            this.currentUpdateItem = {}
            delete newObj[key]
            this.currentUpdateItem = newObj
        })
    }

    updateItem(key, value) {

        runInAction(() => {
                this[key] = value
            }
        )
    }


    getBrands = async () => {

        this.post('/getBrands', {}).then((res) => {
            runInAction(() => {
                this.brands = res.data
            })
        })
    }


    getModels = async () => {
        if (this.currentUpdateItem.brand) {
            this.post('/getModels', {brand: this.currentUpdateItem.brand}).then((res) => {
                runInAction(() => {
                    this.models = res.data
                    this.currentUpdateItem.model = ''

                    this.prices = []
                    this.brokenPrices = []
                    this.updateCurrentItem('storage','')
                    this.updateCurrentItem('color','')
                })
            }).catch((err) => {
                    alert(err)
            })
        }
    }


    getStorageAndColor = async () => {
        if (this.currentUpdateItem.brandid && this.currentUpdateItem.modelid) {
            this.post('/getStorages', {
                brandid: this.currentUpdateItem.brandid,
                modelid: this.currentUpdateItem.modelid
            }).then((res) => {
                runInAction(() => {
                    // alert(JSON.stringify(res.data.data.propertyGroups))
                    Object.keys(res.data.data.propertyGroups).map((item, index) => {
                        if (index == 0) {
                            // alert(item)
                            // alert(res.data.data.propertyGroups[item])
                            this.storage = res.data.data.propertyGroups[item].options
                        }
                        if (index == 1) {
                            this.colors = res.data.data.propertyGroups[item].options
                        }
                    })
                    this.prices = []
                    this.brokenPrices = []
                    this.updateCurrentItem('storage',null)
                    this.updateCurrentItem('color',null)
                    // this.models = res.data
                })
            })
        }
    }


    getPrices = async () => {
        runInAction(()=>{
            this.loading = true
        })
        if (this.currentUpdateItem.brand && this.currentUpdateItem.model && this.currentUpdateItem.storage && this.currentUpdateItem.color) {
            this.post('/getPrices', {
                brand: this.currentUpdateItem.brand,
                model: this.currentUpdateItem.model.split(' ').join(''),
                storage: this.currentUpdateItem.storage.split(' ').join(''),
                color: this.currentUpdateItem.color.split(' ').join('')
            }).then((res) => {
                runInAction(() => {
                    this.prices = res.data
                    this.getBrokenPrices()
                })
            })
        }
    }

    getBrokenPrices = async () => {
        if (this.currentUpdateItem.brand && this.currentUpdateItem.model && this.currentUpdateItem.storage && this.currentUpdateItem.color) {
            this.post('/getBrokenPrices', {
                brand: this.currentUpdateItem.brand,
                model: this.currentUpdateItem.model.split(' ').join(''),
                storage: this.currentUpdateItem.storage.split(' ').join(''),
                color: this.currentUpdateItem.color.split(' ').join('')
            }).then((res) => {
                runInAction(() => {
                    if (res.data.length > 0) {
                        this.prices.splice(this.prices.length - 1, 1)
                        this.prices = this.prices.concat(res.data)
                    }
                    this.brokenPrices = res.data
                    runInAction(()=>{
                        this.loading = false
                    })
                })
            })
        }
    }
    sendRequest(ref){
        this.updateCurrentItem('captcha',false)
        if(
            !this.currentUpdateItem.phone ||
            !this.currentUpdateItem.state ||
            !this.currentUpdateItem.postcode ||
            !this.currentUpdateItem.country ||
            !this.currentUpdateItem.name ||
            !this.currentUpdateItem.email ||
            !this.currentUpdateItem.phone ||
            !this.currentUpdateItem.surname ||
            !this.currentUpdateItem.iban
        ){
            toast('phone'+this.currentUpdateItem.phone)
            toast('state'+this.currentUpdateItem.state)
            toast('postcode'+this.currentUpdateItem.postcode)
            toast('country'+this.currentUpdateItem.country)
            toast('name'+this.currentUpdateItem.name)
            toast('email'+this.currentUpdateItem.email)
            toast('phone'+this.currentUpdateItem.phone)
            toast('surname'+this.currentUpdateItem.surname)
            toast('iban'+this.currentUpdateItem.iban)
            toast("Please fill all the fields", {
                position: "top-right",
                autoClose: 5000,
                type: "error",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })


            return false
        }else{
            if(this.currentUpdateItem.iban.length<20){
                toast("Please enter a valid IBAN", {
                    position: "top-right",
                    autoClose: 5000,
                    type: "error",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                return false
            }else if(this.currentUpdateItem.phone.length<9){
                toast("Please enter a valid phone", {
                    position: "top-right",
                    autoClose: 5000,
                    type: "error",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                return false
            }else if(this.currentUpdateItem.email.indexOf('@')==-1){
                toast("Please enter a valid email", {
                    position: "top-right",
                    autoClose: 5000,
                    type: "error",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                })

                return false
            }else if(this.currentUpdateItem.fulladdress.length<5){
                toast("Please enter a valid address", {
                    position: "top-right",
                    autoClose: 5000,
                    type: "error",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                return false

            }else{

                this.post('/submit', {
                    street: this.currentUpdateItem.street,
                    city: this.currentUpdateItem.city,
                    state: this.currentUpdateItem.state,
                    postcode: this.currentUpdateItem.postcode,
                    country: this.currentUpdateItem.country,
                    fulladdress: this.currentUpdateItem.fulladdress,
                    name: this.currentUpdateItem.name,
                    email: this.currentUpdateItem.email,
                    bank: this.currentUpdateItem.bank,
                    price: this.currentUpdateItem.price,
                    condition: this.currentUpdateItem.condition,
                    phone: this.currentUpdateItem.phone,
                    surname: this.currentUpdateItem.surname,
                    iban: this.currentUpdateItem.iban,
                    brand: this.currentUpdateItem.brand,
                    model: this.currentUpdateItem.model,
                    storage: this.currentUpdateItem.storage,
                    color: this.currentUpdateItem.color
                }).then((res) => {
                    runInAction(() => {
                        toast("Request sent successfully!", {
                            position: "top-right",
                            autoClose: 5000,
                            type: "success",
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
                    setTimeout(()=>{
                        window.location.reload()
                    },2500)
                })
            }
        }

    }

}


export default new AppStore()


